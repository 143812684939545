import { User } from 'react-netlify-identity';

export const usersReadById = async (id: string) => {
  return fetch(`/.netlify/functions/users-read-by-id?id=${id}`).then(
    response => {
      return response.json();
    }
  );
};

export const createFaunaUser = async (
  user: User,
  shopifyCustomerId: string
) => {
  const requestPayload = { user, shopifyCustomerId };

  return fetch(`/.netlify/functions/fauna-user-create`, {
    method: 'POST',
    body: JSON.stringify(requestPayload)
  }).then(response => {
    return response.json();
  });
};

export const usersReadAll = async () => {
  return fetch('/.netlify/functions/users-read-all').then(response => {
    return response.json();
  });
};

export const usernamesReadAll = async () => {
  return fetch('/.netlify/functions/usernames-read-all').then(response => {
    return response.json();
  });
};

export const usersSearchByUsername = async (searchQuery: string) => {
  return fetch(
    `/.netlify/functions/users-search?userQuery=${searchQuery}`
  ).then(response => {
    return response.json();
  });
};

export const identityUserDelete = async (userId: string) => {
  const requestPayload = { id: userId };

  return fetch('/.netlify/functions/identity-user-delete', {
    method: 'DELETE',
    body: JSON.stringify(requestPayload)
  }).then(response => {
    return response.json();
  });
};

export const userDeleteById = async (id: string) => {
  return fetch(`/.netlify/functions/user-delete-by-id?id=${id}`).then(
    response => {
      return response.json();
    }
  );
};
