import React, { createContext, useState } from 'react';

export type LoadingContextType = {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isCustomersLoading: boolean;
  setIsCustomersLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LoadingContext = createContext<LoadingContextType | undefined>(
  undefined!
);

export function LoadingProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);
  const value = {
    isLoading,
    setIsLoading,
    isCustomersLoading,
    setIsCustomersLoading
  };
  return (
    <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
  );
}
