import React, { memo, ReactElement } from 'react';
import { BaseIconProps } from '../../common/interfaces';

const IconUserComponent = (props: BaseIconProps): ReactElement => {
  const { fill = '#000000', width = 20, height = 20 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <path
        fill={fill}
        d="M24 24c4.42 0 8-3.59 8-8 0-4.42-3.58-8-8-8s-8 3.58-8 8c0 4.41 3.58 8 8 8zm0 4c-5.33 0-16 2.67-16 8v4h32v-4c0-5.33-10.67-8-16-8z"
      />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  );
};

export const IconUser = memo(IconUserComponent);
