import React, { memo, ReactElement } from 'react';
import { BaseIconProps } from '../../common/interfaces';

const IconUserCustomsComponent = (props: BaseIconProps): ReactElement => {
  const { fill = '#000000', width = 20, height = 20 } = props;

  return (
    <svg
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 161.02 254.97"
    >
      <g>
        <path
          fill={fill}
          d="M80.51 180a23.71 23.71 0 1 0-23.7-23.71A23.71 23.71 0 0 0 80.51 180Zm0 11.85c-15.79 0-47.4 7.91-47.4 23.7v11.85h94.8v-11.87c0-15.79-31.61-23.7-47.4-23.7Z"
        />
        <rect
          x="5"
          y="5"
          width="151.02"
          height="244.97"
          rx="10"
          fill="none"
          stroke={fill}
          strokeMiterlimit="10"
          strokeWidth="10"
        />
      </g>
    </svg>
  );
};

export const IconUserCustoms = memo(IconUserCustomsComponent);
