import React, { memo } from 'react';

// types
import { CartLineItemProps } from './CartLineItem.types';
import { Link } from '@reach/router';
import { CartLineProvider, Image, Money } from '@shopify/hydrogen-react';

// components
import { IconNoImage } from '../../icons/IconNoImage';
import { ConditionBadge } from '../../common/ConditionBadge/ConditionBadge';
import { CartItemQuantity } from '../CartItemQuantity/CartItemQuantity';

// utils
// import { goToProductPage } from '../../../utils/routing-utils';

// hooks
import { useModal } from '../../../hooks/use-modal';

import './CartLineItem.scss';

const CartLineItemComponent = (props: CartLineItemProps) => {
  const { lineItem } = props;
  const { setIsCartModalOpen } = useModal();

  const { merchandise } = lineItem;

  const firstVariant = merchandise.selectedOptions[0];
  const hasDefaultVariantOnly =
    firstVariant.name === 'Title' && firstVariant.value === 'Default Title';

  return (
    <CartLineProvider line={lineItem}>
      <div className="cart-line-item mb-3 bg-light p-3">
        {/* Image */}
        <div className="d-flex mb-3">
          <div className="me-3 bg-light cart-line-item__image d-flex justify-content-center align-items-center">
            <Link
              to={`/shop/products/${merchandise.product.handle}`}
              onClick={() => setIsCartModalOpen(false)}
            >
              {merchandise.image ? (
                <Image
                  className="rounded"
                  data={merchandise.image}
                  width={110}
                  height={110}
                  alt={merchandise.title}
                />
              ) : (
                <IconNoImage width={80} />
              )}
            </Link>
          </div>
          {/* Title */}
          <div className="me-4">
            <h5>
              <Link
                className="link-dark"
                to={`/shop/products/${merchandise.product.handle}`}
                onClick={() => setIsCartModalOpen(false)}
              >
                {merchandise.product.title}
              </Link>
            </h5>
            {/* Options */}
            {!hasDefaultVariantOnly && (
              <div className="mb-1">
                {merchandise.selectedOptions.map(({ name, value }) => (
                  <ConditionBadge key={name} value={value} />
                ))}
              </div>
            )}
            <h5>
              <Money data={lineItem.cost.totalAmount} />
            </h5>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {/* Quantity */}
          <CartItemQuantity productTitle={merchandise.product.title} />
        </div>
      </div>
    </CartLineProvider>
  );
};

export const CartLineItem = memo<CartLineItemProps>(CartLineItemComponent);
