import { createStorefrontClient } from '@shopify/hydrogen-react';

// common
import { STOREFRONT_API_VERSION } from '../common/constants';

// queries
import { searchProductsQuery } from '../queries/search-products';
import {
  createCustomerQuery,
  customerAccessTokenQuery
} from '../queries/customers';
import { productByIdQuery } from '../queries/product-by-id';

const client = createStorefrontClient({
  privateStorefrontToken: process.env.GATSBY_STOREFRONT_PRIVATE_ACCESS_TOKEN,
  storeDomain: process.env.GATSBY_SHOPIFY_DOMAIN,
  storefrontApiVersion: STOREFRONT_API_VERSION
});

export const getStorefrontApiUrl = client.getStorefrontApiUrl;
export const getPrivateTokenHeaders = client.getPrivateTokenHeaders;

export const getSearchResults = async (searchQuery: string) => {
  const response = await fetch(getStorefrontApiUrl(), {
    body: JSON.stringify({
      // A Storefront API query
      query: searchProductsQuery,
      variables: { first: 100, query: searchQuery }
    }),
    // When possible, add the 'buyerIp' property.
    headers: getPrivateTokenHeaders(),
    method: 'POST'
  });

  const json = await response.json();
  return json && json.data && json.data.search;
};

export const getCustomerAccessToken = async (
  email: string,
  password: string
) => {
  const input = {
    input: {
      email: email,
      password: password
    }
  };
  const response = await fetch(getStorefrontApiUrl(), {
    body: JSON.stringify({
      // A Storefront API query
      query: customerAccessTokenQuery,
      variables: input
    }),
    // When possible, add the 'buyerIp' property.
    headers: getPrivateTokenHeaders(),
    method: 'POST'
  });

  const json = await response.json();
  return json && json.data && json.data.customerAccessTokenCreate;
};

export const createCustomer = async (email: string, password: string) => {
  const input = {
    input: {
      email: email,
      password: password
    }
  };
  const response = await fetch(getStorefrontApiUrl(), {
    body: JSON.stringify({
      // A Storefront API query
      query: createCustomerQuery,
      variables: input
    }),
    // When possible, add the 'buyerIp' property.
    headers: getPrivateTokenHeaders(),
    method: 'POST'
  });

  const json = await response.json();
  return json && json.data && json.data.customerCreate;
};

export const getOrdersByCustomerId = async (id: string) => {
  return fetch(`/.netlify/functions/orders-by-customer-id?id=${id}`).then(
    response => {
      return response;
    }
  );
};

export const getProductById = async (productId: string) => {
  const response = await fetch(getStorefrontApiUrl(), {
    body: JSON.stringify({
      // A Storefront API query
      query: productByIdQuery,
      variables: { id: productId }
    }),
    headers: getPrivateTokenHeaders(),
    method: 'POST'
  });

  const json = await response.json();
  return json && json.data;
};
