// types
import { memo } from 'react';

// types
import { CartEmptyContentProps } from './CartEmptyContent.types';

const CartEmptyContentComponent = (props: CartEmptyContentProps) => {
  const { children } = props;

  return (
    <div className="d-flex flex-column justify-content-center h-100">
      {children || (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <img
            className="w-50 p-3"
            src="https://poketcgshop.s3.amazonaws.com/pokemon-images/chansey/chansey-animated_kiaokk.gif"
            alt="No products in cart"
          />
          There&rsquo;s nothing in here...yet.
        </div>
      )}
    </div>
  );
};

export const CartEmptyContent = memo<CartEmptyContentProps>(
  CartEmptyContentComponent
);
