import { CartCost } from '@shopify/hydrogen-react';

// types
import { CartSummaryProps } from './CartSummary.types';
import { memo } from 'react';

const CartSummaryComponent = (props: CartSummaryProps) => {
  const { hideTotal } = props;

  const rowClassName = 'd-flex justify-content-between mb-1';

  return (
    <div className="p-3 bg-light">
      <div className={rowClassName}>
        <h6>Subtotal:</h6>
        <CartCost amountType="subtotal" />
      </div>
      <div className={rowClassName}>
        <h6>Tax:</h6>
        <CartCost amountType="tax" />
      </div>
      {!hideTotal ? (
        <div className={rowClassName}>
          <h6>Total:</h6>
          <strong>
            <CartCost />
          </strong>
        </div>
      ) : undefined}
    </div>
  );
};

export const CartSummary = memo<CartSummaryProps>(CartSummaryComponent);
