export const DIGITAL_TCG_BASE_ROUTE = 'digital-tcg';
export const RESOURCES_BASE_ROUTE = 'resources-library';
export const SHOP_BASE_ROUTE = 'shop';
export const PRODUCTS_BASE_ROUTE = 'products';
export const GAMES_BASE_ROUTE = 'games';
export const NFT_BASE_ROUTE = 'brokemon-nft';
export const ACCOUNT_BASE_ROUTE = 'account';
export const ADMIN_BASE_ROUTE = 'admin';

export const ROUTES = {
  HOME: '/',
  ADMIN_HOME: `/${ADMIN_BASE_ROUTE}`,
  ADMIN_PRODUCTS_BULK_IMPORT: `/${ADMIN_BASE_ROUTE}/products/import`,
  ADMIN_PRODUCTS_BULK_UPDATE: `/${ADMIN_BASE_ROUTE}/products/update`,
  ADMIN_PRODUCTS_BULK_UPDATE_CATEGORY: `/${ADMIN_BASE_ROUTE}/products/update/:id`,
  ADMIN_CATEGORIES: `/${ADMIN_BASE_ROUTE}/categories`,
  ADMIN_ORDERS: `/${ADMIN_BASE_ROUTE}/orders`,
  ADMIN_USERS: `/${ADMIN_BASE_ROUTE}/users`,
  BACKGROUND_ARTS: `/${RESOURCES_BASE_ROUTE}/background-arts`,
  BROKEMON_NFT_COLLECTION: `/${NFT_BASE_ROUTE}/brokemon-nft-collection`,
  CARD_ARTS: `/${RESOURCES_BASE_ROUTE}/card-artworks`,
  CARD_CREATOR: '/create',
  CART: '/cart',
  CHECKOUT: '/checkout',
  GYM_TRAINER_ARTS: `/${RESOURCES_BASE_ROUTE}/gym-trainer-arts`,
  MOVES_LIBRARY: `/${RESOURCES_BASE_ROUTE}/moves`,
  NFT_ABOUT: `/${NFT_BASE_ROUTE}/about-our-nfts`,
  NFT_ROADMAP: `/${NFT_BASE_ROUTE}/roadmap`,
  ORDER_CONFIRMATION: '/order-confirmation',
  PRIVACY_POLUCY: '/privacy-policy',
  PULL_GAME: `/${GAMES_BASE_ROUTE}/pull-game`,
  PULL_GAME_SET: `/${GAMES_BASE_ROUTE}/pull-game/:id`,
  SEARCH: '/search',
  PRODUCTS: `/${PRODUCTS_BASE_ROUTE}`,
  SET_LISTS: `/${DIGITAL_TCG_BASE_ROUTE}/set-lists`,
  SET_LISTS_SET: `/${DIGITAL_TCG_BASE_ROUTE}/set-lists/:id`,
  SHOP: `/${SHOP_BASE_ROUTE}`,
  SHOP_MODERN: `/${SHOP_BASE_ROUTE}/modern`,
  SHOP_WOTC: `/${SHOP_BASE_ROUTE}`,
  TRAINER_ARTS: `/${RESOURCES_BASE_ROUTE}/trainer-arts`,
  USER_CUSTOMS: `/${DIGITAL_TCG_BASE_ROUTE}/user-customs`,
  USER_ORDERS: `/${ACCOUNT_BASE_ROUTE}/my-orders`,
  USER_PROFILE: `/${ACCOUNT_BASE_ROUTE}/profile`,
  WOTC_CUSTOMS: `/${DIGITAL_TCG_BASE_ROUTE}/wotc-customs`,
  WOTC_SET: `/${DIGITAL_TCG_BASE_ROUTE}/wotc-sets/:id`,
  WOTC_SETS: `/${DIGITAL_TCG_BASE_ROUTE}/wotc-sets`
};
