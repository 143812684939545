import nftIds from '../data/nft-ids.json';

export const stripSpacesFromUsername = (usernameString: string): string => {
  return usernameString.replace(/\s+/g, '');
};

export const getNftIdsFlattenedArray = (): Array<string> => {
  if (!nftIds || !nftIds.length) return [];

  const nftIdStrings = [];
  nftIds.map((nftId: { value: string }) => {
    nftIdStrings.push(nftId.value);
  });

  return nftIdStrings;
};

export const isPremiumUser = (user: any): boolean => {
  if (
    !user ||
    !user.app_metadata ||
    !user.app_metadata.roles ||
    !user.app_metadata.roles.length
  )
    return false;

  return user.app_metadata.roles.includes('pro');
};

export const isAdminUser = (user: any): boolean => {
  if (
    !user ||
    !user.app_metadata ||
    !user.app_metadata.roles ||
    !user.app_metadata.roles.length
  )
    return false;

  return user.app_metadata.roles.includes('admin');
};

// export const isBasicUser = (user: any): boolean => {
//   if (
//     !user ||
//     !user.app_metadata ||
//     !user.app_metadata.roles ||
//     !user.app_metadata.roles.length
//   )
//     return false;

//   return user.app_metadata.roles.includes('basic');
// };

export const sanitizeLoginErrorMessage = (msg: string): string => {
  return msg.replace('invalid_grant', 'Login Error');
};
