export const productByIdQuery = `
  query getProductById($id: ID!) {
    product(id: $id) {
      title
      variants(first: 10) {
        nodes {
          title
          price {
            amount
          }
          quantityAvailable
          id
        }
      }
    }
  }
`;
