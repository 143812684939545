import React, { ChangeEvent, memo } from 'react';
import {
  RPCAlert,
  RPCButton,
  RPCTextInput
} from '@markfazzio/react-pokemon-card';

// types
import { LoginFormProps } from './LoginForm.types';

// components
import { IconAlert } from '../../icons/IconAlert';

const LoginFormComponent = (props: LoginFormProps) => {
  const {
    emailPlaceholder = 'Enter email',
    formState,
    onForgotPasswordClick,
    onEmailChange,
    onEmailClear,
    onPasswordChange,
    onPasswordClear,
    onSubmit,
    passwordPlaceholder = 'Enter password',
    validationMessage
  } = props;

  return (
    <form onSubmit={onSubmit}>
      <RPCTextInput
        className="mb-3"
        label="Email"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (onEmailChange) onEmailChange(event);
        }}
        onClear={() => {
          if (onEmailClear) onEmailClear();
        }}
        placeholder={emailPlaceholder}
        required
        value={formState.email || ''}
      />
      <RPCTextInput
        className="mb-3"
        label="Password"
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (onPasswordChange) onPasswordChange(event);
        }}
        onClear={() => {
          if (onPasswordClear) onPasswordClear();
        }}
        placeholder={passwordPlaceholder}
        required
        type="password"
        value={formState.password || ''}
      />
      {validationMessage && validationMessage !== '' ? (
        <RPCAlert variant="danger" icon={<IconAlert />}>
          {validationMessage}
        </RPCAlert>
      ) : undefined}
      <div className="d-flex align-items-center justify-content-between">
        <RPCButton size="lg" type="submit" label="Sign In" />
        {onForgotPasswordClick ? (
          <RPCButton variant="link" onClick={onForgotPasswordClick}>
            Forgot Password?
          </RPCButton>
        ) : undefined}
      </div>
    </form>
  );
};

export const LoginForm = memo(LoginFormComponent);
