import React, { memo, ReactElement } from 'react';
import {
  RPCAlert,
  RPCButton,
  RPCTextInput
} from '@markfazzio/react-pokemon-card';

// common
// import {getNftIdsFlattenedArray} from '../../common/account-utils';

// hooks
import { SignupFormProps } from './SignupForm.types';
import { IconAlert } from '../../icons/IconAlert';

const SignupFormComponent = (props: SignupFormProps): ReactElement => {
  const {
    formState,
    invalidFields,
    isLoading,
    onConfirmPasswordChange,
    onConfirmPasswordClear,
    onEmailChange,
    onEmailClear,
    onPasswordChange,
    onPasswordClear,
    onUsernameChange,
    onUsernameClear,
    onSubmit,
    validationMessage
  } = props;

  // const query = useQuery();
  // const queryStringId = query.get('id');

  // const nftIds = getNftIdsFlattenedArray();

  // const isValidNftId = !!(
  //   nftIds &&
  //   nftIds.length &&
  //   nftIds.includes(formState.nftId)
  // );

  return (
    <form onSubmit={onSubmit}>
      <RPCTextInput
        className="mb-3"
        isInvalid={
          invalidFields &&
          invalidFields.length &&
          invalidFields.includes('email')
        }
        label="Email"
        type="text"
        placeholder="Enter email"
        onChange={onEmailChange}
        onClear={onEmailClear}
        required
        value={formState.email}
      />
      <RPCTextInput
        className="mb-3"
        isInvalid={
          invalidFields &&
          invalidFields.length &&
          invalidFields.includes('password')
        }
        label="Password"
        onChange={onPasswordChange}
        onClear={onPasswordClear}
        type="password"
        placeholder="Enter password"
        required
        value={formState.password}
      />
      <RPCTextInput
        className="mb-3"
        isInvalid={
          invalidFields &&
          invalidFields.length &&
          invalidFields.includes('confirmPassword')
        }
        label="Confirm Password"
        onChange={onConfirmPasswordChange}
        onClear={onConfirmPasswordClear}
        type="password"
        placeholder="Enter password again"
        required
        value={formState.confirmPassword}
      />
      <RPCTextInput
        className="mb-3"
        isInvalid={
          invalidFields &&
          invalidFields.length &&
          invalidFields.includes('nickname')
        }
        label="Nickname"
        onChange={onUsernameChange}
        onClear={onUsernameClear}
        placeholder="Enter Nickname"
        required
        value={formState.nickname}
      />
      {validationMessage && validationMessage !== '' ? (
        <RPCAlert variant="danger" icon={<IconAlert />}>
          {validationMessage}
        </RPCAlert>
      ) : undefined}
      <RPCButton
        type="submit"
        size="lg"
        label="Create My Account"
        disabled={isLoading}
      />
    </form>
  );
};

export const SignupForm = memo<SignupFormProps>(SignupFormComponent);
