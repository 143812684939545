import { ACCOUNT_MIN_PASSWORD_LENGTH } from '@/common/constants';
import { SignupFormState } from '@/components/account/SignupForm/SignupForm.types';

export const validateForm = (signupFormState: SignupFormState) => {
  const isValidPassword =
    signupFormState.password.length >= ACCOUNT_MIN_PASSWORD_LENGTH;

  const isValidPasswordMatch = !!(
    signupFormState.password !== '' &&
    signupFormState.confirmPassword !== '' &&
    signupFormState.password === signupFormState.confirmPassword
  );

  if (
    !isValidPassword ||
    !isValidPasswordMatch ||
    !signupFormState.nickname ||
    signupFormState.nickname.length < 6
  ) {
    let errorMessage: string = '';
    let errorField: string = '';

    if (!isValidPassword) {
      errorMessage = 'Password must be at least 8 characters';
      errorField = 'password';
    } else if (!isValidPasswordMatch) {
      errorMessage = 'Passwords do not match';
      errorField = 'confirmPassword';
    } else if (!signupFormState.nickname) {
      errorMessage = 'Please enter a username';
      errorField = 'username';
    } else if (signupFormState.nickname.length < 6) {
      errorMessage = 'Username must be at least 6 characters';
      errorField = 'username';
    }

    return {
      isValid: false,
      message: errorMessage,
      field: errorField
    };
  }

  return {
    isValid: true
  };
};

/* 
    if (!isValidPassword) {
      setSignupValidationMessage('Password must be at least 8 characters');
      updateInvalidSignupFields('password');
    } else if (!isValidPasswordMatch) {
      setSignupValidationMessage('Passwords do not match');
      updateInvalidSignupFields('confirmPassword');
    } else if (!signupFormState.nickname) {
      setSignupValidationMessage('Please enter a username');
      updateInvalidSignupFields('username');
    } else if (signupFormState.nickname.length < 6) {
      setSignupValidationMessage('Username must be at least 6 characters');
      updateInvalidSignupFields('username');
    }
      */
