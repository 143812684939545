import React, { createContext, useState } from 'react';

export type ModalContextType = {
  isAccountModalOpen: boolean;
  setIsAccountModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCartModalOpen: boolean;
  setIsCartModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showInitialHelpModal: boolean;
  setShowInitialHelpModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined!
);

export function ModalProvider({ children }) {
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [showInitialHelpModal, setShowInitialHelpModal] = useState(true);
  const value = {
    isAccountModalOpen,
    setIsAccountModalOpen,
    isCartModalOpen,
    setIsCartModalOpen,
    showInitialHelpModal,
    setShowInitialHelpModal
  };
  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
}
