import React, {
  ChangeEvent,
  memo,
  ReactElement,
  SyntheticEvent,
  useState
} from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import {
  RPCButton,
  RPCLoadingIndicator,
  RPCModal
} from '@markfazzio/react-pokemon-card';

// types
import { AccountModalProps } from './AccountModal.types';

// scss
import './AccountModal.scss';

// components
import { LoginForm } from '@/components/account/LoginForm/LoginForm';
import { SignupForm } from '@/components/account/SignupForm/SignupForm';
import { ForgotPasswordForm } from '@/components/account/ForgotPasswordForm/ForgotPasswordForm';

// utils
import { goToSignupSuccessPage } from '@/utils/routing-utils';
import {
  sanitizeLoginErrorMessage,
  stripSpacesFromUsername
} from '@/utils/account-utils';
import { getLowercasedUsernames } from '@/utils/data-utils';
import { createCustomer } from '@/utils/shopify-utils';

// api
import {
  createFaunaUser,
  usernamesReadAll
} from '../../../api-endpoints/users';
import { Logo } from '@/components/icons/Logo';
import { validateForm } from '@/utils/form-utils';

const AccountModalComponent = (props: AccountModalProps): ReactElement => {
  const { onHide, onLoginSuccess, show } = props;

  const {
    requestPasswordRecovery,
    // user,
    // isConfirmedUser,
    loginUser,
    signupUser
  } = useIdentityContext();
  const [isForgotPasswordMode, setIsForgotPasswordMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginValidationMessage, setLoginValidationMessage] = useState('');
  const [signupValidationMessage, setSignupValidationMessage] = useState('');
  const [accountLoadingLabel, setAccountLoadingLabel] = useState('');
  const [invalidSignupFields, setInvalidSignupFields] = useState([]);
  const [loginFormState, setLoginFormState] = useState({
    email: '',
    password: ''
  });
  const [signupFormState, setSignupFormState] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    nickname: ''
    // nftId: queryStringId || '',
  });
  const [isForgotPasswordFormSubmitted, setIsForgotPasswordFormSubmitted] =
    useState(false);
  const [accountRecoveryErrorMessage, setAccountRecoveryErrorMessage] =
    useState('');
  const modalTitle = isForgotPasswordMode ? 'Forgot Password' : 'Account';

  const stopLoading = () => {
    setIsLoading(false);
    setAccountLoadingLabel('');
  };

  const handleForgotPasswordSubmit = async (email: string) => {
    if (email !== '') {
      setIsLoading(true);
      await requestPasswordRecovery(email)
        .then(() => {
          setIsForgotPasswordFormSubmitted(true);
          setIsLoading(false);
        })
        .catch((e: { message: string }) => {
          setAccountRecoveryErrorMessage(e.message);
          setIsLoading(false);
        });
    } else {
      setAccountRecoveryErrorMessage('Please enter an email address.');
    }
  };

  const handleLoginSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);
    setSignupValidationMessage('');
    setAccountLoadingLabel('Signing in...');

    await loginUser(loginFormState.email, loginFormState.password)
      .then(() => {
        if (onLoginSuccess) {
          onLoginSuccess();
        }
      })
      .catch((e: { message: string }) => {
        stopLoading();
        setLoginValidationMessage(sanitizeLoginErrorMessage(e.message));
      });
  };

  const updateLoginFormState = (fieldId: string, fieldValue: string) => {
    setLoginFormState(prevState => ({
      ...prevState,
      [fieldId]: fieldValue
    }));
  };

  const updateSignupFormState = (fieldId: string, fieldValue: string) => {
    setSignupFormState(prevState => ({
      ...prevState,
      [fieldId]: fieldValue
    }));
  };

  const updateInvalidSignupFields = (fieldId: string) => {
    // this is on purpose
    // component expects an array and we want whichever field we send to be highlighted
    setInvalidSignupFields([fieldId]);
  };

  const handleSignupSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();

    setLoginValidationMessage('');
    setAccountLoadingLabel('Creating account...');

    const formValidation = validateForm(signupFormState);

    if (formValidation.isValid) {
      // good to go, sign the user up
      setIsLoading(true);
      let existingUsernames: Array<string>;
      const strippedUsername = stripSpacesFromUsername(
        signupFormState.nickname
      );
      usernamesReadAll().then(async (usernames: any) => {
        if (usernames && usernames.data) {
          existingUsernames = usernames.data;
        }
        const lowercasedUsernames = getLowercasedUsernames(existingUsernames);
        const lowercasedUsername = strippedUsername.toLowerCase();
        if (
          lowercasedUsernames &&
          lowercasedUsernames.includes(lowercasedUsername)
        ) {
          setSignupValidationMessage('Username already exists');
          stopLoading();
        } else {
          setSignupValidationMessage('');
          await createCustomer(signupFormState.email, signupFormState.password)
            .then(async rsp => {
              const shopifyCustomerId = rsp && rsp.customer && rsp.customer.id;
              await signupUser(
                signupFormState.email,
                signupFormState.password,
                {
                  full_name: stripSpacesFromUsername(signupFormState.nickname)
                }
              )
                .then(async userRsp => {
                  await createFaunaUser(userRsp, shopifyCustomerId)
                    .then(() => {
                      goToSignupSuccessPage();
                      stopLoading();
                    })
                    .catch(() => {
                      stopLoading();
                    });
                })
                .catch((e: { message: string }) => {
                  updateInvalidSignupFields('email');
                  setSignupValidationMessage(e.message);
                  stopLoading();
                });
            })
            .catch(() => {
              stopLoading();
            });
        }
      });
    } else {
      setSignupValidationMessage(formValidation.message);
      updateInvalidSignupFields(formValidation.field);
    }
  };

  const handleAccountModalHide = () => {
    if (onHide) onHide();
    setIsForgotPasswordMode(false);
    setAccountRecoveryErrorMessage('');
  };

  const handleBackToLoginClick = () => {
    setIsForgotPasswordMode(false);
    setAccountRecoveryErrorMessage('');
  };

  return (
    <RPCModal
      backdrop={isLoading ? 'static' : undefined}
      closeVariant="white"
      dismissible={!isLoading}
      footer={
        <>
          {isForgotPasswordMode ? (
            <RPCButton
              label="Back to Login"
              variant="primary"
              onClick={handleBackToLoginClick}
            />
          ) : undefined}
        </>
      }
      headerVariant="info"
      size="lg"
      show={show}
      onHide={handleAccountModalHide}
      title={modalTitle}
      className="account-modal"
    >
      <div className="container-fluid">
        <div className="my-4 text-center">
          <Logo className="w-50" />
          <h6 className="text-futura-heavy">Welcome!</h6>
        </div>
        {isLoading ? (
          <RPCLoadingIndicator label={accountLoadingLabel} fill="#000" />
        ) : (
          <>
            {isForgotPasswordMode ? (
              <ForgotPasswordForm
                isFormSubmitted={isForgotPasswordFormSubmitted}
                errorMessage={accountRecoveryErrorMessage}
                onForgotPasswordSubmit={(email: string) =>
                  handleForgotPasswordSubmit(email)
                }
              />
            ) : (
              <div className="row d-flex align-items-stretch">
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <div className="p-3 bg-secondary-subtle bg-gradient h-100">
                    <h5 className="text-futura-heavy py-2">Log In</h5>
                    <LoginForm
                      formState={loginFormState}
                      onEmailChange={(event: ChangeEvent<HTMLInputElement>) => {
                        updateLoginFormState('email', event.target.value);
                      }}
                      onEmailClear={() => {
                        updateLoginFormState('email', '');
                        setLoginValidationMessage('');
                      }}
                      onForgotPasswordClick={() => {
                        setIsForgotPasswordMode(true);
                      }}
                      onPasswordChange={(
                        event: ChangeEvent<HTMLInputElement>
                      ) => {
                        updateLoginFormState('password', event.target.value);
                      }}
                      onPasswordClear={() => {
                        updateLoginFormState('password', '');
                        setLoginValidationMessage('');
                      }}
                      onSubmit={handleLoginSubmit}
                      validationMessage={loginValidationMessage}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="p-3 bg-primary-subtle bg-gradient">
                    <h5 className="text-futura-heavy py-2">Sign Up</h5>
                    <SignupForm
                      invalidFields={invalidSignupFields}
                      isLoading={isLoading}
                      onEmailChange={(event: ChangeEvent<HTMLInputElement>) => {
                        updateSignupFormState('email', event.target.value);
                      }}
                      onEmailClear={() => {
                        updateSignupFormState('email', '');
                      }}
                      onConfirmPasswordChange={(
                        event: ChangeEvent<HTMLInputElement>
                      ) => {
                        updateSignupFormState(
                          'confirmPassword',
                          event.target.value
                        );
                      }}
                      onConfirmPasswordClear={() => {
                        updateSignupFormState('confirmPassword', '');
                      }}
                      onPasswordChange={(
                        event: ChangeEvent<HTMLInputElement>
                      ) => {
                        updateSignupFormState('password', event.target.value);
                      }}
                      onPasswordClear={() => {
                        updateSignupFormState('password', '');
                      }}
                      onUsernameChange={(
                        event: ChangeEvent<HTMLInputElement>
                      ) => {
                        const strippedUsername = stripSpacesFromUsername(
                          event.target.value
                        );
                        updateSignupFormState('nickname', strippedUsername);
                      }}
                      onUsernameClear={() => {
                        updateSignupFormState('nickname', '');
                      }}
                      formState={signupFormState}
                      onSubmit={handleSignupSubmit}
                      validationMessage={signupValidationMessage}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </RPCModal>
  );
};

export const AccountModal = memo<AccountModalProps>(AccountModalComponent);
