import { graphql, useStaticQuery } from 'gatsby';

export const useQueryAllProducts = () => {
  return useStaticQuery(graphql`
    query {
      allShopifyProduct(sort: { createdAt: DESC }) {
        edges {
          node {
            title
            handle
            hasOnlyDefaultVariant
            totalInventory
            media {
              preview {
                image {
                  src
                  gatsbyImageData
                }
              }
            }
            metafields {
              key
              value
              type
            }
            variants {
              id
              shopifyId
              price
              inventoryQuantity
              image {
                src
              }
              media {
                preview {
                  image {
                    gatsbyImageData
                  }
                }
              }
              title
            }
          }
        }
      }
    }
  `);
};
