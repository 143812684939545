import React, { memo, ReactElement } from 'react';
import { BaseIconProps } from '../../common/interfaces';

const IconNoImageComponent = (props: BaseIconProps): ReactElement => {
  const { fill, height, width } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 455 455"
      xmlSpace="preserve"
      height={height}
      width={width}
    >
      <path
        fill={fill}
        d="M360.967 130.599a7.5 7.5 0 0 0-2.981 14.701c1.147.232 2.013 1.286 2.013 2.45v160c0 1.355-1.145 2.5-2.5 2.5H179.676c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5H357.5c9.649 0 17.5-7.851 17.5-17.5v-160c0-8.29-5.901-15.503-14.033-17.151zM274.824 130.25H97.5c-9.649 0-17.5 7.851-17.5 17.5v160a17.47 17.47 0 0 0 13.326 16.982 7.52 7.52 0 0 0 1.803.221 7.5 7.5 0 0 0 1.792-14.783c-.955-.235-1.92-1.143-1.92-2.42v-160c0-1.355 1.145-2.5 2.5-2.5h177.324a7.5 7.5 0 1 0-.001-15z"
      />
      <path
        fill={fill}
        d="M235.363 170.798a58.276 58.276 0 0 0-7.863-.548c-31.706 0-57.5 25.794-57.5 57.5 0 2.563.185 5.209.548 7.863a7.501 7.501 0 0 0 8.448 6.413 7.5 7.5 0 0 0 6.413-8.448 43.211 43.211 0 0 1-.409-5.828c0-23.435 19.065-42.5 42.5-42.5 1.884 0 3.845.138 5.828.409a7.496 7.496 0 0 0 8.448-6.413 7.5 7.5 0 0 0-6.413-8.448zM219.127 284.636c2.789.407 5.605.614 8.373.614 31.706 0 57.5-25.794 57.5-57.5 0-2.77-.207-5.587-.613-8.373a7.502 7.502 0 0 0-8.505-6.337 7.5 7.5 0 0 0-6.337 8.505c.303 2.071.456 4.158.456 6.205 0 23.435-19.065 42.5-42.5 42.5-2.044 0-4.132-.153-6.205-.456-4.099-.6-7.907 2.238-8.505 6.337s2.237 7.906 6.336 8.505zM318.5 203.25c9.098 0 16.5-7.402 16.5-16.5 0-8.318-6.227-15.355-14.484-16.37a7.513 7.513 0 0 0-6.218 2.142l-10.027 10.027a7.499 7.499 0 0 0-2.141 6.217c1.015 8.257 8.053 14.484 16.37 14.484zM117.5 114.75h30c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-30c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5z"
      />
      <path
        fill={fill}
        d="M388.367 66.633C345.397 23.664 288.268 0 227.5 0S109.603 23.664 66.633 66.633C23.664 109.603 0 166.732 0 227.5s23.664 117.897 66.633 160.867C109.603 431.336 166.732 455 227.5 455s117.897-23.664 160.867-66.633C431.336 345.397 455 288.268 455 227.5s-23.664-117.897-66.633-160.867zM15 227.5C15 110.327 110.327 15 227.5 15c55.894 0 106.807 21.703 144.783 57.11L72.11 372.283C36.703 334.307 15 283.395 15 227.5zM227.5 440c-55.894 0-106.807-21.703-144.783-57.11L382.89 82.717C418.297 120.693 440 171.606 440 227.5 440 344.673 344.673 440 227.5 440z"
      />
    </svg>
  );
};

export const IconNoImage = memo(IconNoImageComponent);
