import React, { memo, ReactElement } from 'react';
import { RPCBadge } from '@markfazzio/react-pokemon-card';
import { ConditionBadgeProps } from './ConditionBadge.types';

const ConditionBadgeComponent = (props: ConditionBadgeProps): ReactElement => {
  const { value } = props;

  const getBadgeBg = (): string => {
    switch (value) {
      case 'DMG':
      case 'HP':
      case 'HP/DMG':
        return 'danger';
      case 'MP':
        return 'warning';
      case 'LP':
        return 'primary';
      case 'LP-NM':
        return 'info';
      case 'NM':
        return 'success';
      case 'NM-M':
        return 'black';
    }
  };

  return <RPCBadge bg={getBadgeBg()}>{value}</RPCBadge>;
};

export const ConditionBadge = memo<ConditionBadgeProps>(
  ConditionBadgeComponent
);
