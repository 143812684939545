import React, { createContext, useState } from 'react';

export type MessageContextType = {
  cartMessage: string;
  setCartMessage: React.Dispatch<React.SetStateAction<string>>;
};

export const MessageContext = createContext<MessageContextType | undefined>(
  undefined!
);

export function MessageProvider({ children }) {
  const [cartMessage, setCartMessage] = useState('');
  const value = {
    cartMessage,
    setCartMessage
  };
  return (
    <MessageContext.Provider value={value}>{children}</MessageContext.Provider>
  );
}
