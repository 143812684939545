import { ShopifyProvider } from '@shopify/hydrogen-react';
import { STOREFRONT_API_VERSION } from '../common/constants';

export const StoreProvider = ({ children }: any) => {
  return (
    <ShopifyProvider
      storeDomain={process.env.GATSBY_SHOPIFY_DOMAIN}
      storefrontToken={process.env.GATSBY_STOREFRONT_ACCESS_TOKEN}
      storefrontApiVersion={STOREFRONT_API_VERSION}
      countryIsoCode="US"
      languageIsoCode="EN"
    >
      {children}
    </ShopifyProvider>
  );
};
