import React, { memo, ReactElement, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import { RPCButton, RPCLoadingIndicator } from '@markfazzio/react-pokemon-card';
import { Product } from '@shopify/hydrogen-react/storefront-api-types';
import { GatsbyImage } from 'gatsby-plugin-image';

// types
import { SiteSearchProps } from './SiteSearch.types';

// scss
import './SiteSearch.scss';

// components
import { IconSearch } from '../../icons/IconSearch';
import { IconNoImage } from '../../icons/IconNoImage';

// queries
import { useQueryAllProducts } from '../../../queries/all-products';

// utils
import { getSearchOptionsFromProducts } from '../../../utils/data-utils';
import {
  goToProductPage,
  goToSearchResultsPage
} from '../../../utils/routing-utils';

const SiteSearchComponent = (props: SiteSearchProps): ReactElement => {
  const {
    emptyLabel,
    id,
    isLoading,
    labelKey = 'title',
    options,
    // optionNoPhotoContent = <IconNoImage />,
    placeholder
  } = props;
  const [searchValue, setSearchValue] = useState('');
  // search
  const allProductsData = useQueryAllProducts();
  const allProducts = allProductsData.allShopifyProduct.edges;
  const searchOptions = options || getSearchOptionsFromProducts(allProducts);

  return (
    <div className="site-search d-flex">
      <AsyncTypeahead
        className="w-100"
        emptyLabel={emptyLabel}
        id={id}
        filterBy={['title', 'handle']}
        positionFixed
        isLoading={isLoading}
        labelKey={labelKey}
        onSearch={(query: string) => {
          setSearchValue(query);
        }}
        onChange={(selectedOption: Array<Product>) => {
          if (selectedOption && selectedOption[0] && selectedOption[0].handle) {
            goToProductPage(selectedOption[0].handle);
          }
        }}
        options={searchOptions}
        placeholder={placeholder}
        renderMenuItemChildren={(option: Product, { text }) => (
          <div className="d-flex align-items-center">
            {option.media && option.media[0] ? (
              <GatsbyImage
                className="autocomplete-item__image"
                image={option.media[0].preview.image.gatsbyImageData}
                alt={option.title}
              />
            ) : (
              <div className="autocomplete-item__image">
                <IconNoImage />
              </div>
            )}
            <div className="autocomplete-item__title">
              <Highlighter search={text}>
                {option[labelKey as string]}
              </Highlighter>
            </div>
          </div>
        )}
        searchText={
          <div className="d-flex justify-content-center">
            <RPCLoadingIndicator fill="#999" />
          </div>
        }
      />
      <RPCButton
        className="site-search__submit"
        icon={<IconSearch height={24} width={24} />}
        variant="outline-light"
        type="submit"
        onClick={() => {
          if (searchValue !== '') goToSearchResultsPage(searchValue);
        }}
      />
    </div>
  );
};

export const SiteSearch = memo<SiteSearchProps>(SiteSearchComponent);
