import React, { memo, ReactElement } from 'react';

const LogoSmallComponent = (props: { alt?: boolean }): ReactElement => {
  const { alt } = props;
  const fillOne = alt ? '#888' : '#3d5ba6';
  const fillTwo = alt ? '#222' : '#ffcc06';
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 519.42 118.56">
      <path
        fill={fillOne}
        d="M0 15.75c4.84-.84 9.38-1.6 13.64-2.29 4.25-.69 8.71-1.28 13.38-1.78 4.67-.49 9.11-.77 13.33-.85 3.24-.06 6.52.15 9.84.65 5.53.82 9.12 1.52 10.79 2.08 2.52.88 4.85 2.05 6.99 3.5a21.88 21.88 0 0 1 5.51 5.35c1.53 2.12 2.69 4.52 3.45 7.21.3 1.1.37 2.22.2 3.36-.64 4.27-3.76 9.18-9.36 14.74-5.6 5.56-11.1 10.31-16.5 14.25s-9.41 6.76-12.04 8.43l4.92 37.61-16.59 8.57-15.05-78.86c-.08-.01-.49.3-1.25.92-.76.63-1.75 1.45-2.97 2.45A91.479 91.479 0 0 1 6 42.94c-.11-.02-.36-.89-.75-2.62s-.88-4.14-1.5-7.21c-.61-3.07-1.31-6.49-2.1-10.25C.87 19.1.31 16.73-.01 15.75Zm30.77 21.42 4.74 17.05c2.39-2.43 5.36-5.74 8.9-9.94 3.55-4.2 5.32-7.25 5.32-9.13 0-.73-.31-1.16-.92-1.27-1.73-.39-3.45-.58-5.14-.58-5.09 0-9.38 1.29-12.89 3.87Z"
      />
      <path
        fill={fillOne}
        d="M111.58 50.35c2.74 1.46 4.96 3.44 6.68 5.92 1.71 2.49 2.97 5.28 3.76 8.38.79 3.1 1.18 6.33 1.18 9.68 0 2.77-.22 5.45-.66 8.03s-1.05 4.95-1.82 7.11-1.68 3.99-2.72 5.49c-2.43 3.62-5.03 6.74-7.8 9.36-2.78 2.62-5.61 4.73-8.5 6.33-2.89 1.6-5.75 2.77-8.58 3.52s-5.64 1.13-8.41 1.13c-3.28 0-6.39-.51-9.34-1.53s-5.46-2.36-7.54-4.02c-3.43-2.85-6.11-6.7-8.03-11.53s-2.89-9.95-2.89-15.35c0-9.29 2.68-16.78 8.03-22.49 4.59-4.89 9.33-8.58 14.22-11.07 4.89-2.48 9.9-3.73 15.03-3.73 3.01 0 5.9.38 8.67 1.13 2.78.75 5.68 1.96 8.73 3.61Zm-3.41 9.71c-.66 2.93-2.07 5.51-4.25 7.75s-4.31 3.35-6.39 3.35c-1.04 0-1.97-.27-2.78-.81-1.16-.93-1.73-2.54-1.73-4.86 0-1.77.59-3.53 1.76-5.26 1.18-1.73 3.04-3.1 5.58-4.1-2.54 0-5 .48-7.37 1.45s-4.4 2.21-6.1 3.73c-1.7 1.52-3.05 3.24-4.07 5.14-1.02 1.91-1.53 3.83-1.53 5.75 0 3.58 1.66 6.43 4.97 8.55 3.08 1.97 6.01 2.95 8.79 2.95s5.16-.92 7.28-2.75 3.74-4.18 4.86-7.05 1.68-5.71 1.68-8.53c0-1.92-.23-3.7-.69-5.32ZM121.66 11.33 148.69 0l3.94 61.46 22.24-12.18 23.09 3.46-30.12 23.09 28.36 21.39 1.28 21.34-41.82-30.06 1.33 26.13-25.33.48-4.79-92.43-7.82 1.76 2.61-13.09ZM256.85 80.99l13.93 16.82c-1.12 3.24-3.77 6.21-7.95 8.9-4.18 2.7-9.15 4.83-14.91 6.39-5.76 1.56-11.51 2.34-17.25 2.34-5.09 0-9.62-.68-13.61-2.02-3.99-1.35-7.06-3.39-9.22-6.13-3.74-4.7-5.61-10.71-5.61-18.03 0-5.78 1.05-11.47 3.15-17.08 2.1-5.61 4.33-9.53 6.68-11.76 2.81-2.66 5.87-4.87 9.16-6.65 3.29-1.77 6.71-3.1 10.26-3.99a44.67 44.67 0 0 1 10.92-1.33c4.89 0 9.55.78 13.96 2.34 4.41 1.56 8.28 3.83 11.59 6.79l-31.73 37.86c2.81 0 5.48-.59 8.01-1.76 2.52-1.17 4.85-2.85 6.97-5.03s4.01-4.73 5.66-7.66Zm-44.94-51.78 27.8-21.61 16.22 18.05-35.85 12.81-8.18-9.25Zm28.93 38.31c-1.89-.96-3.81-1.45-5.78-1.45-2.08 0-4.1.43-6.04 1.27-1.95.85-3.32 1.85-4.13 3.01-2.39 3.08-3.58 6.47-3.58 10.17 0 1.35.13 2.64.4 3.87s.73 2.93 1.39 5.09l17.75-21.96Z"
      />
      <path
        fill={fillTwo}
        d="m293.14 112.71-3.81-74.39-25.74 1.04.52-22.77 76.88-4.62 1.44 25.09-24.21.34 4.05 75.61M430.52 40.69l-25.9 16.94c-1.73-4.31-7.53-6.47-17.4-6.47-2.24 0-4.53 1.6-6.88 4.8-2.35 3.2-3.53 6.8-3.53 10.81.19 3.04 1.1 5.96 2.72 8.76s4.49 4.29 8.61 4.48c3.08 0 6.32-.83 9.71-2.49 3.39-1.66 6.32-4.97 8.79-9.94.15.31 1.75 1.75 4.8 4.34 3.04 2.58 6.16 5.16 9.33 7.75 3.18 2.58 5.52 4.49 7.02 5.72-2.93 7.01-6.8 12.5-11.62 16.47-4.82 3.97-9.87 6.69-15.14 8.15-5.28 1.47-10.52 2.2-15.72 2.2-7.28 0-13.69-1.38-19.22-4.13-5.53-2.76-10.07-7.48-13.61-14.16-3.54-6.69-5.7-15.73-6.47-27.14-.08-1.12-.12-2.81-.12-5.09 0-5.7.66-11.18 1.99-16.42 1.33-5.24 3.51-9.97 6.53-14.19 3.02-4.22 7.2-7.66 12.51-10.32 5.32-2.66 11.75-4.05 19.31-4.16 5.01 0 9.94.59 14.8 1.76s9.31 2.81 13.35 4.91 7.52 4.66 10.43 7.69c2.91 3.03 4.81 6.27 5.69 9.74ZM488.42 110.34c-2.51 1.23-7.55 1.85-15.14 1.85s-13.69-1.38-19.22-4.13c-5.53-2.76-10.07-7.48-13.61-14.16s-5.7-15.73-6.47-27.14c-.08-1.12-.11-2.81-.11-5.09 0-5.7.66-11.18 1.99-16.42 1.33-5.24 3.51-9.97 6.53-14.19 3.02-4.22 7.2-7.66 12.51-10.32 5.32-2.66 11.75-4.05 19.31-4.16 5.01 0 9.94.59 14.8 1.76 4.86 1.17 9.31 2.81 13.35 4.91 4.05 2.1 7.52 4.66 10.43 7.69 2.91 3.03 4.81 6.27 5.69 9.74l-25.89 16.94c-1.73-4.31-7.53-6.47-17.4-6.47-2.24 0-4.53 1.6-6.88 4.8-2.35 3.2-3.53 6.8-3.53 10.81.19 3.04 1.1 5.96 2.72 8.76s4.49 4.29 8.61 4.48c.35 0 .95-.1 1.82-.32.87-.21 1.64-.4 2.31-.58s1.36-.34 2.05-.49l-4.28-1.91 6.65-15.14 32.66 12.31 2.1 42.72-29.21.72"
      />
    </svg>
  );
};

export const LogoSmall = memo(LogoSmallComponent);
