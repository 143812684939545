import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import {
  IconPlusCircle,
  RPCButton,
  RPCNavbar,
  RPCNavbarDropdown,
  RPCNavbarLink,
  RPCOffCanvas
} from '@markfazzio/react-pokemon-card';
import { useIdentityContext } from 'react-netlify-identity';
import { useCart } from '@shopify/hydrogen-react';
import { StringParam, useQueryParam } from 'use-query-params';

// routing
import {
  DIGITAL_TCG_BASE_ROUTE,
  GAMES_BASE_ROUTE,
  // NFT_BASE_ROUTE,
  // RESOURCES_BASE_ROUTE,
  ROUTES,
  SHOP_BASE_ROUTE
} from '../../../routing/paths';

// types
import { AppHeaderProps } from './AppHeader.types';

// scss
import './AppHeader.scss';

// common
import {
  COOKIE_POLICY_MSG,
  DISCORD_SERVER_URL,
  MENU_ITEM_NAMES
} from '../../../common/constants';

// utils
import {
  goToCardCreatorPage,
  goToHomePage,
  goToLogoutSuccessPage,
  goToShopPage,
  isMenuItemActive
} from '../../../utils/routing-utils';
import { isAdminUser } from '../../../utils/account-utils';

// hooks
import { useModal } from '../../../hooks/use-modal';

// menu items
import {
  DIGITAL_TCG_ITEMS,
  GAMES_ITEMS,
  USER_ITEMS
} from '../../../common/menu-dropdowns';

// components
// import {SiteSearch} from '../../common/SiteSearch/SiteSearch';
import { IconUser } from '../../icons/IconUser';
import { IconCart } from '../../icons/IconCart';
import { LogoSmall } from '../../icons/LogoSmall';
import { IconLogout } from '../../icons/IconLogout';
import { AccountModal } from '../../account/AccountModal/AccountModal';
import { CartModal } from '../../cart/CartModal/CartModal';
import { IconDiscord } from '../../icons/IconDiscord';
import classNames from 'classnames';

const AppHeaderComponent = (props: AppHeaderProps): ReactElement => {
  const defaultCookieWarningState =
    typeof window !== 'undefined' ? localStorage.getItem('cookie-policy') : '';

  const { extraOptions, extraOptionsMobile, isPromoCodeActive } = props;

  const { totalQuantity } = useCart();

  // hooks
  const location = useLocation();
  const { isLoggedIn, isConfirmedUser, logoutUser, user, getFreshJWT } =
    useIdentityContext();
  const { isAccountModalOpen, setIsAccountModalOpen, setIsCartModalOpen } =
    useModal();

  // cookie modal
  const [isCookieWarningOpen, setIsCookieWarningOpen] = useState(
    defaultCookieWarningState
  );

  // mobile menu
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  // tokenization
  const tokenObject = user && user.token;
  const tokenExpires = tokenObject && tokenObject.expires_at;
  const nowDate = Date.now();

  // query string functionality
  const [queryStringAction] = useQueryParam('action', StringParam);

  // menu booleans
  const isCustomCardsMenuActive = isMenuItemActive(
    DIGITAL_TCG_BASE_ROUTE,
    location
  );
  const isGamesMenuActive = isMenuItemActive(GAMES_BASE_ROUTE, location);
  const isShopMenuActive = isMenuItemActive(SHOP_BASE_ROUTE, location);

  const handleLogout = () => {
    logoutUser().then(() => {
      goToLogoutSuccessPage();
    });
  };

  const USER_ITEMS_WITH_LOGOUT = [
    ...USER_ITEMS,
    {
      icon: <IconLogout />,
      id: 'logout',
      label: 'Logout',
      onClick: handleLogout
    }
  ];

  const NAVBAR_USER_ITEM = !user
    ? {
        id: 'login',
        onClick: () => setIsAccountModalOpen(true),
        icon: <IconUser />,
        label: MENU_ITEM_NAMES.LOGIN_SIGNUP
      }
    : {
        id: 'logout',
        type: 'dropdown',
        items: USER_ITEMS_WITH_LOGOUT,
        label: 'My Account'
      };

  const navbarRightItems = [
    {
      id: 'discord',
      icon: <IconDiscord />,
      label: 'Our Discord',
      onClick: () => window.open(DISCORD_SERVER_URL, '_blank').focus()
    },
    {
      id: 'cart',
      label: 'Cart',
      badgeContent: totalQuantity,
      onClick: () => setIsCartModalOpen(true),
      icon: <IconCart />
    },
    NAVBAR_USER_ITEM
  ];

  const appHeaderClassNames = classNames({
    'app-header': true,
    'has-admin-bar': user && isAdminUser(user),
    'has-promo-code': isPromoCodeActive
  });

  // {user && isAdminUser(user) ? (
  //   <>
  //     <RPCNavbarDropdown
  //       active={isGamesMenuActive}
  //       activeItem={
  //         location.pathname.includes('admin') ? 'admin' : undefined
  //       }
  //       items={ADMIN_ITEMS}
  //       id="admin-menu"
  //       label={MENU_ITEM_NAMES.ADMIN}
  //       openOnHover={!isMobileOpen}
  //     />
  //   </>
  // ) : undefined}

  const handleSetCookiePolicyAccepted = () => {
    if (typeof window !== 'undefined') {
      localStorage.setItem('cookie-policy', 'accepted');
    }
    setIsCookieWarningOpen('accepted');
  };

  // const handleSetCookiePolicyDenied = () => {
  //   setIsCookieWarningOpen('');
  // };

  const getActiveDigitalTCGItem = (): string => {
    let returnStr: string;

    if (location.pathname.includes('wotc-sets')) returnStr = 'wotc-sets';
    if (location.pathname.includes('user-customs')) returnStr = 'user-customs';
    if (location.pathname.includes('set-lists')) returnStr = 'set-lists';

    return returnStr;
  };

  useEffect(() => {
    if (queryStringAction && queryStringAction === 'signup') {
      setIsAccountModalOpen(true);
    }
  }, [queryStringAction]);

  // check user for expired token
  useEffect(() => {
    // console.log('tokenExpires', new Date(tokenExpires));
    // console.log('nowDate', new Date(nowDate));
    if (tokenExpires && tokenExpires < nowDate) {
      getFreshJWT()
        .then((res: any) => {
          // eslint-disable-next-line no-console
          // console.log('res', res);
          return res;
        })
        .catch((err: any) => {
          if (user) {
            logoutUser()
              .then((res: any) => {
                // eslint-disable-next-line no-console
                // console.log('res', JSON.stringify(res));
                return res;
              })
              .catch((logoutError: any) => {
                // eslint-disable-next-line no-console
                // console.log('logoutError', logoutError);
                return logoutError;
              });
          } else {
            // eslint-disable-next-line no-console
            // console.log('else navigate');
            goToHomePage();
          }
          // eslint-disable-next-line no-console
          // console.log('err', JSON.stringify(err));
          return err;
        });
    }

    // if logged in, confirmed with expired token
    if (isLoggedIn && isConfirmedUser && !tokenObject) {
      logoutUser()
        .then(() => {
          goToHomePage();
        })
        .catch((err: any) => {
          // eslint-disable-next-line no-console
          // console.log('logoutError', err);
          return err;
        });
    }
  }, [getFreshJWT, nowDate, tokenObject]);

  return (
    <header className={appHeaderClassNames}>
      <RPCNavbar
        variant="light"
        className="main-navigation bg-light"
        extraOptionsMobile={
          <>
            {extraOptionsMobile}
            <RPCButton
              badgeLabel={totalQuantity}
              icon={<IconCart width={24} height={24} fill="#fff" />}
              variant="dark"
              className="btn-cart"
              onClick={() => setIsCartModalOpen(true)}
            />
          </>
        }
        isMobileShowing={isMobileOpen}
        logo={<LogoSmall alt />}
        navbarRightItems={navbarRightItems}
        onLogoClick={() => goToHomePage()}
        onHideMobileMenu={() => setIsMobileOpen(false)}
        openDropdownsOnHover={!isMobileOpen}
        onToggleClick={() => setIsMobileOpen(!isMobileOpen)}
        // searchComponent={
        //   <>
        //     {!hideSearch ? (
        //       <SiteSearch id="site-search" placeholder="Search for a card" />
        //     ) : null}
        //   </>
        // }
      >
        <RPCNavbarLink
          active={location.pathname === ROUTES.HOME}
          label="Home"
          onClick={() => {
            setIsMobileOpen(false);
            goToHomePage();
          }}
        />
        <RPCNavbarLink
          active={isShopMenuActive}
          label="Shop"
          onClick={() => {
            setIsMobileOpen(false);
            goToShopPage();
          }}
        />
        {/* <RPCNavbarDropdown
          active={isShopMenuActive}
          activeChild={activeChild}
          activeItem={strippedLocationString}
          errorContent={<CategoriesMenuError />}
          items={SHOP_MENU_ITEMS}
          id="shop-menu"
          label={MENU_ITEM_NAMES.SHOP}
          loadingLabel="Loading shop menu..."
          onSecondLevelChildItemClick={() => setIsMobileOpen(false)}
          openOnHover={!isMobileOpen}
        /> */}
        <RPCNavbarDropdown
          active={isCustomCardsMenuActive}
          activeItem={getActiveDigitalTCGItem()}
          items={DIGITAL_TCG_ITEMS}
          id="digital-tcg-menu"
          label={MENU_ITEM_NAMES.DIGITAL_TCG}
          openOnHover={!isMobileOpen}
        />
        {/* <RPCNavbarDropdown
          active={isNftMenuActive}
          activeItem={location.pathname}
          items={nftItems}
          id="nft-menu"
          label={MENU_ITEM_NAMES.BROKEMON_NFT}
        /> */}
        <RPCNavbarDropdown
          active={isGamesMenuActive}
          activeItem={
            location.pathname.includes('pull-game') ? 'pull-game' : undefined
          }
          items={GAMES_ITEMS}
          id="games-menu"
          label={MENU_ITEM_NAMES.GAMES}
          openOnHover={!isMobileOpen}
        />
        {/* <RPCNavbarDropdown
          active={isCardResourcesMenuActive}
          activeItem={location.pathname}
          items={cardResourcesItems}
          id="card-resources-menu"
          label="Card Resources"
        /> */}
        {!location.pathname.includes(ROUTES.CARD_CREATOR) ? (
          <RPCButton
            className="btn-create"
            icon={<IconPlusCircle fill="#fff" />}
            iconPosition="right"
            label="Create"
            onClick={() => {
              goToCardCreatorPage();
            }}
            variant="info"
          />
        ) : undefined}
        {extraOptions}
      </RPCNavbar>
      <RPCOffCanvas
        variant="dark"
        actionsChildren={
          <>
            <RPCButton
              label="Accept"
              variant="primary"
              onClick={handleSetCookiePolicyAccepted}
            />
            {/* <RPCButton
              label="Deny"
              variant="danger"
              onClick={handleSetCookiePolicyDenied}
            /> */}
          </>
        }
        show={
          !isCookieWarningOpen ||
          isCookieWarningOpen === '' ||
          isCookieWarningOpen !== 'accepted'
        }
        onHide={() => setIsCookieWarningOpen('dismissed')}
        title="This site uses cookies"
        placement="bottom"
      >
        <div className="container">
          <div className="row">
            <div className="col">{COOKIE_POLICY_MSG}</div>
          </div>
        </div>
      </RPCOffCanvas>
      <AccountModal
        onHide={() => setIsAccountModalOpen(false)}
        onLoginSuccess={() => setIsAccountModalOpen(false)}
        show={isAccountModalOpen}
      />
      <CartModal />
    </header>
  );
};

export const AppHeader = memo<AppHeaderProps>(AppHeaderComponent);
