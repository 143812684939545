import { CartProvider as ShopifyCartProvider } from '@shopify/hydrogen-react';

export const CartProvider = ({ children }: any) => {
  return (
    <ShopifyCartProvider
      onLineAdd={() => {
        console.log('a line is being added');
      }}
      onLineAddComplete={() => {
        console.log('a line has been added');
      }}
    >
      {children}
    </ShopifyCartProvider>
  );
};
