import React from 'react';
import { navigate } from 'gatsby';
import { WindowLocation } from '@reach/router';

// paths
import { PRODUCTS_BASE_ROUTE, ROUTES, SHOP_BASE_ROUTE } from '../routing/paths';

// components
import { IconShop } from '../components/icons/IconShop';

export const goToHomePage = () => navigate(ROUTES.HOME);

export const goToProductPage = (productSlug: string) => {
  navigate(`/${SHOP_BASE_ROUTE}/${PRODUCTS_BASE_ROUTE}/${productSlug}`);
};

export const goToShopPage = () => {
  navigate(ROUTES.SHOP);
};

export const goToShopVintageCardsPage = () => {
  navigate(ROUTES.SHOP_WOTC);
};

export const goToCategoryPage = (categorySlug: string) => {
  navigate(`${ROUTES.SHOP}/${categorySlug}`);
};

export const goToChildCategoryPage = (
  categorySlug: string,
  parentCategorySlug: string,
  breadcrumbs?: Array<any>
) => {
  let pagePath = ROUTES.SHOP;
  if (breadcrumbs && breadcrumbs.length) {
    if (breadcrumbs[0] && breadcrumbs[0].permalink) {
      pagePath += `/${breadcrumbs[0].permalink}`;
    }
    if (breadcrumbs[1] && breadcrumbs[1].permalink) {
      pagePath += `/${breadcrumbs[1].permalink}`;
    }
    if (breadcrumbs[2] && breadcrumbs[2].permalink) {
      pagePath += `/${breadcrumbs[2].permalink}`;
    }
  }
  if (parentCategorySlug) {
    pagePath += `/${parentCategorySlug}`;
  }

  navigate(`${pagePath}/${categorySlug}`);
};

export const goToCheckoutPage = (cartId: string) => {
  navigate(`${ROUTES.CHECKOUT}?id=${cartId}`);
};

export const goToOrderConfirmationPage = (orderId: string) => {
  navigate(`${ROUTES.ORDER_CONFIRMATION}?order=${orderId}`);
};

export const goToCartPage = () => {
  navigate(ROUTES.CART);
};

export const DEFAULT_SHOP_BREADCRUMB_ITEM = {
  title: (
    <div className="d-flex align-items-center">
      <IconShop fill="#fff" width={16} height={16} />
      <span>&nbsp;&nbsp;Shop</span>
    </div>
  ),
  onClick: () => goToShopPage()
};

export const DEFAULT_DIGITAL_TCG_BREADCRUMB_ITEM = {
  title: 'Digital TCG Sets',
  onClick: () => goToWotcSetsPage()
};

export const goToSearchResultsPage = (query: string) => {
  navigate(`${ROUTES.SEARCH}?q=${query}`);
};

export const goToCardCreatorPage = () => {
  navigate(ROUTES.CARD_CREATOR);
};

export const goToCardCreatorCardPage = (
  setId: string,
  cardId: string,
  firstEdition?: boolean,
  shadowless?: boolean
) => {
  const objectName = setId === 'user-customs' ? 'custom' : 'card';

  let route = `${ROUTES.CARD_CREATOR}?set=${setId}&${objectName}=${cardId}`;
  if (firstEdition) {
    route += '&firstEdition=1';
  }
  if (shadowless) {
    route += '&shadowless=1';
  }
  navigate(route);
};

export const goToCardCreatorUserCardPage = (cardId: string) => {
  navigate(`${ROUTES.CARD_CREATOR}?set=user-customs&custom=${cardId}`);
};

export const goToUserCustomsPage = () => {
  navigate(ROUTES.USER_CUSTOMS);
};

export const goToUserProfilePage = (queryString?: string) => {
  let userProfileRoute = ROUTES.USER_PROFILE;
  if (queryString) userProfileRoute += `?${queryString}=true`;

  navigate(userProfileRoute);
};

export const goToUserOrdersPage = () => {
  navigate(ROUTES.USER_ORDERS);
};

export const goToPullGamePage = () => {
  navigate(ROUTES.PULL_GAME);
};

export const goToPullGameSetPage = (setId: string, firstEdition?: boolean) => {
  if (firstEdition) {
    if (setId === 'base-set') {
      navigate(`${ROUTES.PULL_GAME}/${setId}?firstEdition=1&shadowless=1`);
    } else {
      navigate(`${ROUTES.PULL_GAME}/${setId}?firstEdition=1`);
    }
  } else {
    navigate(`${ROUTES.PULL_GAME}/${setId}`);
  }
};

export const goToWotcSetPage = (setId: string) => {
  navigate(`${ROUTES.WOTC_SETS}/${setId}`);
};

export const goToSetListsPage = () => {
  navigate(ROUTES.SET_LISTS);
};

export const goToSetListSetPage = (setId: string) => {
  navigate(`${ROUTES.SET_LISTS}/${setId}`);
};

export const goToWotcSetsPage = () => {
  navigate(ROUTES.WOTC_SETS);
};

export const goToWotcCustomsPage = () => {
  navigate(ROUTES.WOTC_CUSTOMS);
};

export const goToSignupSuccessPage = () => {
  navigate(`${ROUTES.HOME}?signup=success`);
};

export const goToLogoutSuccessPage = () => {
  navigate(`${ROUTES.HOME}?logout=success`);
};

export const goToNftPage = () => {
  navigate(ROUTES.BROKEMON_NFT_COLLECTION);
};

export const goToNftRoadmapPage = () => {
  navigate(ROUTES.NFT_ROADMAP);
};

export const goToNftAboutPage = () => {
  navigate(ROUTES.NFT_ABOUT);
};

export const isMenuItemActive = (
  itemName: string,
  location: WindowLocation
) => {
  if (!itemName || !location || !location.pathname) return false;

  return location.pathname.includes(itemName);
};

export const goToAdminHomePage = () => {
  navigate(ROUTES.ADMIN_HOME);
};

export const goToAdminUsersPage = () => {
  navigate(ROUTES.ADMIN_USERS);
};

export const goToAdminCategoriesPage = () => {
  navigate(ROUTES.ADMIN_CATEGORIES);
};

export const goToBulkImportProductsPage = () => {
  navigate(ROUTES.ADMIN_PRODUCTS_BULK_IMPORT);
};

export const goToBulkUpdateProductsPage = () => {
  navigate(ROUTES.ADMIN_PRODUCTS_BULK_UPDATE);
};

export const goToBulkUpdateProductsCategoryPage = (categoryId: string) => {
  navigate(`${ROUTES.ADMIN_PRODUCTS_BULK_UPDATE}/${categoryId}`);
};

export const goToAdminOrdersPage = () => {
  navigate(ROUTES.ADMIN_ORDERS);
};
