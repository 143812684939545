export const searchProductsQuery = `
  query searchProducts($query: String!, $first: Int)  {
    search(query: $query, first: $first, types: PRODUCT)  {
      edges {
        node  {
          ... on Product {
            id
            title
            handle
            totalInventory
            media(first:1) {
              edges {
                node {
                  ... fieldsForMediaTypes
                }
              }
            }
          }
        }
      }
    }
  }

  fragment fieldsForMediaTypes on Media {
    alt
    mediaContentType
    ... on Video {
      id
      sources {
        format
        height
        mimeType
        url
        width
      }
    }
    ... on ExternalVideo {
      id
      host
      embeddedUrl
    }
    ... on Model3d {
      sources {
        format
        mimeType
        url
      }
    }
    ... on MediaImage {
      id
      image {
        altText
        url
      }
    }
  }
`;
