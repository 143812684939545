import React, { memo } from 'react';
import { BaseIconProps } from '../../common/interfaces';

const IconShopComponent = (props: BaseIconProps) => {
  const { fill, width = 24, height = 24, viewBox = '0 0 24 24' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <path
        fill={fill}
        fillRule="evenodd"
        d="M4.876 3.75h14.248L21 8.867V10.5a2.94 2.94 0 0 1-.75 1.967V21H3.75v-8.533A2.94 2.94 0 0 1 3 10.5V8.867L4.876 3.75Zm13.311 9.643c.194 0 .382-.02.563-.058V19.5H15V15H9v4.5H5.25v-6.165a2.766 2.766 0 0 0 2.625-.868 2.759 2.759 0 0 0 4.125 0 2.759 2.759 0 0 0 4.125 0c.51.566 1.239.926 2.063.926ZM10.5 19.5h3v-3h-3v3Zm9-9.75v.75c0 .796-.614 1.393-1.313 1.393-.698 0-1.312-.597-1.312-1.393v-.75H19.5Zm-.324-1.5-1.1-3H5.924l-1.1 3h14.352ZM4.5 9.75v.75c0 .796.614 1.393 1.313 1.393.698 0 1.312-.597 1.312-1.393v-.75H4.5Zm4.125 0v.75c0 .796.614 1.393 1.313 1.393.698 0 1.312-.597 1.312-1.393v-.75H8.625Zm4.125 0v.75c0 .796.614 1.393 1.313 1.393.698 0 1.312-.597 1.312-1.393v-.75H12.75Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const IconShop = memo<BaseIconProps>(IconShopComponent);
