import React, { ReactElement, useEffect, useState } from 'react';
import {
  RPCButton,
  RPCLoadingIndicator,
  RPCAlert
} from '@markfazzio/react-pokemon-card';
import { useIdentityContext } from 'react-netlify-identity';

// components
import { AppPage } from '../layout/AppPage/AppPage';
// import { AdminDataGrid } from './AdminDataGrid/AdminDataGrid';

// api
import {
  identityUserDelete,
  userDeleteById,
  usersReadAll
} from '../../api-endpoints/users';

// utils
import { isAdminUser } from '../../utils/account-utils';
import { formatUsersDataFromFauna } from '../../utils/data-utils';

// hooks
import { useLoading } from '../../hooks/use-loading';
import { SiteUser } from '../../common/interfaces';

// components
import { ConfirmationModal } from '../common/ConfirmationModal/ConfirmationModal';

export const AdminUsersPage = (): ReactElement => {
  const [currentFaunaUsers, setCurrentFaunaUsers] = useState([]);
  const [currentUsersMessage, setCurrentUsersMessage] = useState('');
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isConfirmModalShowing, setIsConfirmModalShowing] = useState(false);
  const { isLoading, setIsLoading } = useLoading();
  const { user, getFreshJWT } = useIdentityContext();
  // const [selectedRows, setSelectedRows] = useState<ReadonlySet<Key>>(
  //   () => new Set()
  // );
  const [pendingRows, setPendingRows] = useState([]);

  // const columns: Array<Column<unknown>> = [
  //   SelectColumn,
  //   {
  //     key: 'email',
  //     name: 'Email',
  //     sortable: true
  //   },
  //   {
  //     key: 'netlifyUsername',
  //     name: 'Netlify Username',
  //     sortable: true
  //   },
  //   {
  //     key: 'netlifyId',
  //     name: 'Netlify ID',
  //     sortable: true
  //   },
  //   {
  //     key: 'created',
  //     name: 'Created',
  //     renderSummaryCell({ row }: any) {
  //       if (!row || !row.created) return null;
  //       const creationDate = new Date(row.created);
  //       return <>{creationDate.toLocaleString()}</>;
  //       // return <>{new Date(props.row.created)}</>;
  //     },
  //     resizable: true,
  //     sortable: true
  //   }
  // ];

  // const rowKeyGetter = (row: any) => {
  //   return row.id;
  // };

  const confirmDeleteUsers = async () => {
    if (!pendingRows || !pendingRows.length) return;

    const usersToDelete = currentFaunaUsers.filter((user: SiteUser) => {
      return pendingRows.includes(user.id);
    });

    setIsUsersLoading(true);
    // first get fresh token
    await getFreshJWT()
      .then(() => {
        usersToDelete.forEach(async (user: SiteUser) => {
          // delete identity user
          await identityUserDelete(user.netlifyId).then(async rsp => {
            if (rsp.code === 401) {
              setCurrentUsersMessage('401 Error: Unauthorized.');
            } else {
              // delete fauna user
              await userDeleteById(user.id).then(faunaUserDeleteRsp => {
                console.log(faunaUserDeleteRsp);
                setCurrentUsersMessage(faunaUserDeleteRsp);
              });
              // TODO: delete user's cards
            }
          });
        });
        setIsUsersLoading(false);
      })
      .catch(error => {
        setCurrentUsersMessage('Error fetching JWT token');
        setIsUsersLoading(false);
        throw error;
      });
    setIsConfirmModalShowing(false);
  };

  useEffect(() => {
    // Fetch all users
    setIsLoading(true);
    usersReadAll().then(faunaUsers => {
      if (!faunaUsers || !faunaUsers.data) return;

      setCurrentFaunaUsers(formatUsersDataFromFauna(faunaUsers.data, true));
      setIsLoading(false);
    });
  }, []);

  return (
    <AppPage
      isLoading={isLoading}
      title={`Admin: Users (${currentFaunaUsers.length || 0})`}
      description="Edit or delete current users (Note: this will remove the Netlify and connected Fauna user)."
    >
      <section className="site-section">
        <div className="container">
          {isAdminUser(user) ? (
            <>
              <div className="row">
                <div className="col">
                  {currentUsersMessage ? (
                    <RPCAlert variant="dark">{currentUsersMessage}</RPCAlert>
                  ) : undefined}
                  {!isUsersLoading ? (
                    <>
                      {currentFaunaUsers && currentFaunaUsers.length ? (
                        <>
                          {/* <AdminDataGrid
                            rows={currentFaunaUsers}
                            columns={columns}
                            onRowsChange={rows => {
                              setCurrentFaunaUsers(rows);
                            }}
                            rowKeyGetter={rowKeyGetter}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={(selectedRows: Set<Key>) => {
                              setPendingRows(Array.from(selectedRows));
                              setSelectedRows(selectedRows);
                            }}
                          /> */}
                          <div className="data-grid-actions">
                            <RPCButton
                              variant="danger"
                              label="Delete Users"
                              disabled={!pendingRows || !pendingRows.length}
                              onClick={() => setIsConfirmModalShowing(true)}
                            />
                            <RPCButton
                              label="Save Changes"
                              // disabled={!pendingRows || !pendingRows.length}
                            />
                          </div>

                          <ConfirmationModal
                            title="Confirm users deletion"
                            show={isConfirmModalShowing}
                            onCancel={() => setIsConfirmModalShowing(false)}
                            onConfirm={() => confirmDeleteUsers()}
                          >
                            <p>
                              This will delete the {pendingRows.length} selected
                              user
                              {pendingRows.length > 1 ? 's' : undefined}.
                              Continue?
                            </p>
                          </ConfirmationModal>
                        </>
                      ) : undefined}
                    </>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <RPCLoadingIndicator label="Loading users..." />
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : (
            <h2>Must be logged in to view this page.</h2>
          )}
        </div>
      </section>
    </AppPage>
  );
};
