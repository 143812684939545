exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-my-orders-tsx": () => import("./../../../src/pages/account/my-orders.tsx" /* webpackChunkName: "component---src-pages-account-my-orders-tsx" */),
  "component---src-pages-account-profile-tsx": () => import("./../../../src/pages/account/profile.tsx" /* webpackChunkName: "component---src-pages-account-profile-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-digital-tcg-user-customs-tsx": () => import("./../../../src/pages/digital-tcg/user-customs.tsx" /* webpackChunkName: "component---src-pages-digital-tcg-user-customs-tsx" */),
  "component---src-pages-digital-tcg-wotc-sets-index-tsx": () => import("./../../../src/pages/digital-tcg/wotc-sets/index.tsx" /* webpackChunkName: "component---src-pages-digital-tcg-wotc-sets-index-tsx" */),
  "component---src-pages-games-pull-game-index-tsx": () => import("./../../../src/pages/games/pull-game/index.tsx" /* webpackChunkName: "component---src-pages-games-pull-game-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-confirmation-tsx": () => import("./../../../src/pages/order-confirmation.tsx" /* webpackChunkName: "component---src-pages-order-confirmation-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-templates-digital-tcg-set-tsx": () => import("./../../../src/templates/digital-tcg-set.tsx" /* webpackChunkName: "component---src-templates-digital-tcg-set-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/product-details.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */),
  "component---src-templates-pull-game-set-tsx": () => import("./../../../src/templates/pull-game-set.tsx" /* webpackChunkName: "component---src-templates-pull-game-set-tsx" */),
  "component---src-templates-shop-category-tsx": () => import("./../../../src/templates/shop-category.tsx" /* webpackChunkName: "component---src-templates-shop-category-tsx" */)
}

