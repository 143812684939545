import React, { memo, ReactElement } from 'react';
import {
  RPCAlert,
  RPCButton,
  RPCOffCanvas
} from '@markfazzio/react-pokemon-card';
import {
  CartCheckoutButton,
  // ShopPayButton,
  useCart
} from '@shopify/hydrogen-react';
import { CartLine } from '@shopify/hydrogen-react/storefront-api-types';

// hooks
import { useModal } from '../../../hooks/use-modal';
import { useMessage } from '../../../hooks/use-message';

// components
import { CartSummary } from '../CartSummary/CartSummary';
import { CartLineItem } from '../CartLineItem/CartLineItem';
import { CartEmptyContent } from '../CartEmptyContent/CartEmptyContent';
import { IconAlert } from '../../icons/IconAlert';
import { IconCart } from '../../icons/IconCart';

// common

const CartModalComponent = (): ReactElement => {
  const { isCartModalOpen, setIsCartModalOpen } = useModal();
  const { cartMessage, setCartMessage } = useMessage();
  const { lines, totalQuantity, error } = useCart();

  // const storeDomain = process.env.GATSBY_SHOPIFY_DOMAIN;
  // // const firstCartLine = lines[0];

  // const shopPayLineItems =
  //   lines &&
  //   lines.map((line: CartLine) => ({
  //     id: line.merchandise.id,
  //     quantity: line.quantity,
  //   }));

  return (
    <RPCOffCanvas
      actionsChildren={
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 mb-2 mb-md-0">
              <RPCButton
                label="Continue Shopping"
                onClick={() => setIsCartModalOpen(false)}
                size="lg"
                variant="primary"
                className="d-block w-100"
              />
            </div>
            <div className="col-sm-6">
              {totalQuantity > 0 ? (
                <CartCheckoutButton className="btn btn-lg btn-warning d-block w-100">
                  Checkout
                </CartCheckoutButton>
              ) : // <ShopPayButton
              //   variantIdsAndQuantities={shopPayLineItems}
              //   storeDomain={storeDomain}
              // />
              undefined}
            </div>
          </div>
        </div>
      }
      className="cart-modal"
      closeButton
      fluidContent={lines.length === 0}
      placement="end"
      show={isCartModalOpen}
      onHide={() => {
        setIsCartModalOpen(false);
        setCartMessage('');
      }}
      title="Your Cart"
    >
      {error ? (
        <RPCAlert variant="danger" icon={<IconAlert />} dismissible>
          {error && error[0] && error[0].message}
        </RPCAlert>
      ) : undefined}
      {cartMessage ? (
        <RPCAlert
          variant="success"
          icon={<IconCart fill="#000" />}
          dismissible
          onClose={() => setCartMessage('')}
        >
          {cartMessage}
        </RPCAlert>
      ) : undefined}
      {totalQuantity > 0 ? (
        <>
          <div className="flex-grow" aria-label="Shopping cart">
            {lines.map((line: CartLine) => {
              return <CartLineItem key={line.id} lineItem={line} />;
            })}
          </div>
          <CartSummary />
        </>
      ) : (
        <CartEmptyContent onClose={() => setIsCartModalOpen(false)} />
      )}
    </RPCOffCanvas>
  );
};

export const CartModal = memo(CartModalComponent);
