/*
    CustomerAccessTokenQuery
    Logged-in user should match shopify user here or this will not work
    Input:
    {
        "input": {
            "email": "CUSTOMER@EMAIL.COM",
            "password": "PASSWORD"
        }
    }
*/

export const customerAccessTokenQuery = `
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
        customerAccessToken {
            accessToken
            expiresAt
        }
        customerUserErrors {
            code
            field
            message
        }
    }
  }
`;

/*
  CustomerQuery
  Get customer from logged in email/password user
  Input:
    {
        "customerAccessToken": "CUSTOMER_ACCESS_TOKEN" 
    }
*/

export const customerQuery = `
  query getCustomer($customerAccessToken: String!)  {
    customer(customerAccessToken: $customerAccessToken) {
        id,
        firstName,
        lastName,
        orders(first: 10) {
            edges {
                node {
                    id
                    name
                    totalPrice
                    fulfillmentStatus
                }
            }
        }
    }
  }
`;

/*
    CustomerAccessTokenQuery
    Logged-in user should match shopify user here or this will not work
    Input:
    {
        "input": {
            "email": "CUSTOMER@EMAIL.COM",
            "password": "PASSWORD"
        }
    }
*/
export const createCustomerQuery = `
    mutation customerCreate($input: CustomerCreateInput!) {
        customerCreate(input: $input) {
        customerUserErrors {
            code
            field
            message
        }
        customer {
            id
        }
        }
    }
`;
