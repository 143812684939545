import React from 'react';
import { useIdentityContext } from 'react-netlify-identity';
import { isAdminUser } from 'src/utils/account-utils';
import { goToHomePage } from 'src/utils/routing-utils';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useIdentityContext();
  const isAllowed = user && isAdminUser(user);

  if (!isAllowed) {
    goToHomePage();
    return null;
  }

  return <Component {...rest} />;
};
