import React, { memo } from 'react';
import { BaseIconProps } from '../../common/interfaces';

const IconAlertComponent = (props: BaseIconProps) => {
  const { fill, width = 24, height = 24 } = props;

  return (
    <svg
      fill={fill}
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 123.996 123.996"
    >
      <path d="M9.821 118.048h104.4c7.3 0 12-7.7 8.7-14.2l-52.2-92.5c-3.601-7.199-13.9-7.199-17.5 0l-52.2 92.5c-3.2 6.5 1.5 14.2 8.8 14.2zm60.401-21.5c0 4.8-3.5 8.5-8.5 8.5s-8.5-3.7-8.5-8.5v-.2c0-4.8 3.5-8.5 8.5-8.5s8.5 3.7 8.5 8.5v.2zm-13.101-62.5h9.801c2.699 0 4.3 2.3 4 5.2l-4.301 37.6c-.3 2.7-2.1 4.4-4.6 4.4s-4.3-1.7-4.6-4.4l-4.301-37.6c-.299-2.9 1.302-5.2 4.001-5.2z" />
    </svg>
  );
};

export const IconAlert = memo<BaseIconProps>(IconAlertComponent);
