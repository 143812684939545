import {
  CartLineQuantity,
  CartLineQuantityAdjustButton
} from '@shopify/hydrogen-react';
import { IconRemove } from '@markfazzio/react-pokemon-card';

// types
import { CartItemQuantityProps } from './CartItemQuantity.types';

// components
// import { CartUpdateButton } from '../CartUpdateButton/CartUpdateButton';
import { memo } from 'react';
import { useMessage } from '../../../hooks/use-message';

const CartItemQuantityComponent = (props: CartItemQuantityProps) => {
  const { productTitle, qtyLabel = 'Qty' } = props;
  const { setCartMessage } = useMessage();

  // const prevQuantity = Number(Math.max(0, lineQuantity - 1).toFixed(0));
  // const nextQuantity = Number((lineQuantity + 1).toFixed(0));

  return (
    <div className="d-flex align-items-center gap-2">
      <div className="me-1">
        {qtyLabel}: <CartLineQuantity />
      </div>

      <CartLineQuantityAdjustButton
        adjust="increase"
        className="btn btn-primary me-1"
      >
        +1
      </CartLineQuantityAdjustButton>
      <CartLineQuantityAdjustButton
        adjust="decrease"
        className="btn btn-info me-1"
      >
        -1
      </CartLineQuantityAdjustButton>
      <CartLineQuantityAdjustButton
        adjust="remove"
        className="btn btn-danger"
        onClick={() =>
          setCartMessage(`${productTitle} has been removed from your cart.`)
        }
      >
        <IconRemove fill="#fff" />
      </CartLineQuantityAdjustButton>
    </div>
  );
};

export const CartItemQuantity = memo<CartItemQuantityProps>(
  CartItemQuantityComponent
);
