import type { GatsbyBrowser } from 'gatsby';
import React from 'react';
import { Router } from '@reach/router';
import { IdentityContextProvider } from 'react-netlify-identity';
import { FacebookProvider } from 'react-facebook';
import { QueryParamProvider } from 'use-query-params';
import { ReachAdapter } from 'use-query-params/adapters/reach';

// Vendor SCSS
// React Pokemon Card CSS (REQUIRED)
import '@markfazzio/react-pokemon-card/react-pokemon-card.css';
// rig styles
import 'react-image-gallery/styles/scss/image-gallery.scss';
// rbt styles
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

// pages css
import '../scss/root.scss';
import '../scss/home.scss';
import '../scss/cart.scss';
import '../scss/card-creator.scss';
import '../scss/checkout.scss';
import '../scss/product-details.scss';
import '../scss/shop.scss';
import '../scss/card-set.scss';
import '../scss/about-nft.scss';
import '../scss/brokemon-collection.scss';
import '../scss/my-orders.scss';
import '../scss/set-lists.scss';
import '../scss/admin.scss';

// common
import { FACEBOOK_APP_ID, SITE_URL } from '../common/constants';

// contexts
import { LoadingProvider } from '../context/LoadingContext';
import { ModalProvider } from '../context/ModalContext';
import { StoreProvider } from '../context/StoreContext';
import { CartProvider } from '../context/CartContext';
import { MessageProvider } from 'src/context/MessageContext';
import { PrivateRoute } from '../components/common/PrivateRoute/PrivateRoute';
import { AdminUsersPage } from '../components/admin/AdminUsersPage';
import { AdminHomePage } from '../components/admin/AdminHomePage';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element
}) => {
  return (
    <IdentityContextProvider url={`${SITE_URL}/`}>
      <FacebookProvider appId={FACEBOOK_APP_ID}>
        <LoadingProvider>
          <ModalProvider>
            <MessageProvider>
              <StoreProvider>
                <CartProvider>
                  <QueryParamProvider adapter={ReachAdapter}>
                    <Router>
                      <PrivateRoute path="/admin" component={AdminHomePage} />
                      <PrivateRoute
                        path="/admin/users"
                        component={AdminUsersPage}
                      />
                      {element}
                    </Router>
                  </QueryParamProvider>
                </CartProvider>
              </StoreProvider>
            </MessageProvider>
          </ModalProvider>
        </LoadingProvider>
      </FacebookProvider>
    </IdentityContextProvider>
  );
};

export default wrapPageElement;
