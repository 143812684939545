import React from 'react';
import { RPCButton } from '@markfazzio/react-pokemon-card';

// components
import { AppPage } from '../layout/AppPage/AppPage';

// utils
import {
  // goToAdminCategoriesPage,
  goToAdminUsersPage
  // goToBulkImportProductsPage,
  // goToBulkUpdateProductsPage,
  // goToCreateCategoryPage,
  // goToCreateProductPage,
} from '../../utils/routing-utils';

const btnVariant = 'dark';
const btnClass = 'd-block w-100';
const btnSize = 'lg';

export const AdminHomePage = () => (
  <AppPage
    title="PokeTCGShop Site Admin"
    description="Manage inventory, bulk import, etc."
  >
    <section className="site-section">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-4 admin-action">
            <h3>Users</h3>
            <RPCButton
              className={btnClass}
              onClick={() => goToAdminUsersPage()}
              variant={btnVariant}
              label="Manage Users"
              size={btnSize}
            />
          </div>
        </div>
      </div>
    </section>
  </AppPage>
);
