import { GatsbyBrowser } from 'gatsby';

export { wrapPageElement } from './src/pages/app';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  routerProps: { location }
}) => {
  const { pathname } = location;
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/create/`, `/pull-game/`, `/user-customs/`];
  const isShopSubPage = pathname.includes('shop');
  const isAccountSubPage = pathname.includes('account');
  const isWotcSetSubPage = pathname.includes('wotc-sets');
  const isScrollToTopRoute = scrollToTopRoutes.indexOf(pathname) > -1;

  if (
    location.search === '' &&
    (isScrollToTopRoute ||
      isWotcSetSubPage ||
      isShopSubPage ||
      isAccountSubPage)
  ) {
    window.scrollTo(0, 0);
  }

  return false;
};
