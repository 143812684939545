import { WizardsStamp } from '@markfazzio/react-pokemon-card';
import { navigate } from '@reach/router';

// routing utils
import {
  goToAdminUsersPage,
  goToPullGamePage,
  goToUserCustomsPage,
  goToUserOrdersPage,
  goToUserProfilePage,
  // goToWotcCustomsPage,
  goToWotcSetsPage
} from '../utils/routing-utils';

// routing constants
import { ROUTES } from '../routing/paths';

// constants
import { MENU_ITEM_NAMES } from './constants';

// components
import { IconUserCustoms } from '../components/icons/IconUserCustoms';
import { IconGames } from '../components/icons/IconGames';
import { IconOrders } from '../components/icons/IconOrders';
import { IconUser } from '../components/icons/IconUser';

export const CARD_RESOURCES_ITEMS = [
  {
    id: ROUTES.CARD_ARTS,
    label: 'Card Arts',
    onClick: () => navigate(ROUTES.CARD_ARTS)
  },
  {
    id: ROUTES.GYM_TRAINER_ARTS,
    label: 'Gym Trainer Arts',
    onClick: () => navigate(ROUTES.GYM_TRAINER_ARTS)
  },
  {
    id: ROUTES.TRAINER_ARTS,
    label: 'Trainer Arts',
    onClick: () => navigate(ROUTES.TRAINER_ARTS)
  },
  {
    id: ROUTES.BACKGROUND_ARTS,
    label: 'Background Arts',
    onClick: () => navigate(ROUTES.BACKGROUND_ARTS)
  },
  {
    id: ROUTES.MOVES_LIBRARY,
    label: 'Moves Library',
    onClick: () => navigate(ROUTES.MOVES_LIBRARY)
  }
];

export const DIGITAL_TCG_ITEMS = [
  {
    icon: <WizardsStamp />,
    id: 'wotc-sets',
    badgeContent: 'BETA',
    label: MENU_ITEM_NAMES.WOTC_CARDS,
    onClick: () => goToWotcSetsPage()
  },
  // {
  //   id: ROUTES.WOTC_CUSTOMS,
  //   label: MENU_ITEM_NAMES.WOTC_ANIMATED,
  //   onClick: () => goToWotcCustomsPage(),
  // },
  {
    icon: <IconUserCustoms />,
    id: 'user-customs',
    badgeContent: 'BETA',
    label: MENU_ITEM_NAMES.USER_CUSTOMS,
    onClick: () => goToUserCustomsPage()
  }
];

// NOTE: logout is added to these via the app header
export const USER_ITEMS = [
  {
    icon: <IconUser />,
    id: ROUTES.USER_PROFILE,
    label: 'Profile',
    onClick: () => goToUserProfilePage()
  }
  // {
  //   icon: <IconOrders />,
  //   id: ROUTES.USER_ORDERS,
  //   label: 'My Orders',
  //   onClick: () => goToUserOrdersPage()
  // }
];

export const GAMES_ITEMS = [
  {
    icon: <IconGames />,
    id: 'pull-game',
    badgeContent: 'BETA',
    label: 'Pull Game',
    onClick: () => goToPullGamePage()
  }
];

export const ADMIN_ITEMS = [
  {
    id: 'manage-users',
    label: 'Manage Users',
    onClick: () => goToAdminUsersPage()
  }
  // ...CARD_RESOURCES_ITEMS,
];

// export const NFT_ITEMS = [
//   {
//     id: ROUTES.BROKEMON_NFT_COLLECTION,
//     label: MENU_ITEM_NAMES.BROKEMON_NFT,
//     onClick: () => goToNftPage(),
//   },
//   {
//     id: ROUTES.NFT_ABOUT,
//     label: MENU_ITEM_NAMES.ABOUT,
//     onClick: () => goToNftAboutPage(),
//   },
//   {
//     id: ROUTES.NFT_ROADMAP,
//     label: MENU_ITEM_NAMES.ROADMAP,
//     onClick: () => goToNftRoadmapPage(),
//   },
// ];
